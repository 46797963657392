import {
  TIME_TO_SALE_FETCH_REQUEST, TIME_TO_SALE_FETCH_SUCCESS, TIME_TO_SALE_FETCH_FAILURE
} from './types'

export default {
  [TIME_TO_SALE_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [TIME_TO_SALE_FETCH_SUCCESS] (state, { data }) {
    state.single[data.type] = data.timeServiceData
    state.loading = false
  },
  [TIME_TO_SALE_FETCH_FAILURE] (state) {
    state.loading = false
  },
}
