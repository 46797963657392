import {
  TIME_TO_SALE_FETCH_REQUEST,
  TIME_TO_SALE_FETCH_SUCCESS,
  TIME_TO_SALE_FETCH_FAILURE
} from './types'

export default {
  fetchImpressionKitTimeToSale ({ commit }, { from, to }) {
    commit(TIME_TO_SALE_FETCH_REQUEST)
    return window.axios.get(`/time-to-sale/impression-kits`, { params: { from, to } }).then(response => {
      commit(TIME_TO_SALE_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(TIME_TO_SALE_FETCH_FAILURE, error)
      throw error
    })
  },
  fetchAlignerTimeToSale ({ commit }, { from, to }) {
    commit(TIME_TO_SALE_FETCH_REQUEST)
    return window.axios.get(`/time-to-sale/aligners`, { params: { from, to } }).then(response => {
      commit(TIME_TO_SALE_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(TIME_TO_SALE_FETCH_FAILURE, error)
      throw error
    })
  },
  fetchScanAppointmentTimeToSale ({ commit }, { from, to }) {
    commit(TIME_TO_SALE_FETCH_REQUEST)
    return window.axios.get(`/time-to-sale/scan-appointments`, { params: { from, to } }).then(response => {
      commit(TIME_TO_SALE_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(TIME_TO_SALE_FETCH_FAILURE, error)
      throw error
    })
  },
}