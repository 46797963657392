<template>
  <router-view />
</template>

<script>
import timeToSaleModule from '../../store/time-to-sale'

export default {
  beforeCreate () {
    this.$store.registerModule('time-to-sale', timeToSaleModule)
  },
  destroyed () {
    this.$store.unregisterModule('time-to-sale')
  }
}
</script>
