import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const namespaced = true

export default {
  namespaced,
  state: () => ({
    validationErrors: {},
    loading: false,
    single: {
      impression_kits: {},
      aligners: {},
      scan_appointments: {}
    },
    data: [],
  }),
  mutations,
  getters,
  actions
}
